@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,800&family=PT+Mono&family=Unbounded:wght@400;700;900&family=Work+Sans&display=swap');

body {
  font-family: 'Bricolage Grotesque', sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}
h1 {
  color: #333;
}

.share-button {
  position: relative;
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.share-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
} 

.file-input-wrapper {
  margin-top: 60px;
  position: relative;
  overflow: hidden;
  display: inline-block;
  background: linear-gradient(45deg, #ff6b6b, #feca57);
  padding: 12px 30px;
  border-radius: 25px;
  color: white;
  font-weight: bold;
  cursor: po;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.file-input-wrapper:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}
.file-input-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}
#result {
  margin-top: 20px;
  margin-bottom: 20px;
}
progress {
  width: 100%;
  height: 30px;
  border-radius: 15px;
  overflow: hidden;
}
progress::-webkit-progress-bar {
  background-color: #f0f0f0;
}
progress::-webkit-progress-value {
  background: linear-gradient(45deg, #ff6b6b, #feca57);
}
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s ease infinite;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
#loadingText {
  display: none;
  margin-top: 20px;
  font-weight: bold;
  color: #333;
}

.bottom-div {
  margin-top: 60px;
  margin-bottom: 20px;
}

.buymeacoffee {
  margin-top: 60px;
}