@import "https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,800&family=PT+Mono&family=Unbounded:wght@400;700;900&family=Work+Sans&display=swap";
body {
  max-width: 800px;
  text-align: center;
  margin: 0 auto;
  padding: 20px;
  font-family: Bricolage Grotesque, sans-serif;
}

h1 {
  color: #333;
}

.share-button {
  color: #fff;
  cursor: pointer;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  transition: all .3s;
  display: inline-block;
  position: relative;
  box-shadow: 0 4px 6px #0000001a;
}

.share-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 8px #00000026;
}

.file-input-wrapper {
  color: #fff;
  cursor: po;
  background: linear-gradient(45deg, #ff6b6b, #feca57);
  border-radius: 25px;
  margin-top: 60px;
  padding: 12px 30px;
  font-weight: bold;
  transition: all .3s;
  display: inline-block;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 6px #0000001a;
}

.file-input-wrapper:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 8px #00000026;
}

.file-input-wrapper input[type="file"] {
  opacity: 0;
  cursor: pointer;
  font-size: 100px;
  position: absolute;
  top: 0;
  left: 0;
}

#result {
  margin-top: 20px;
  margin-bottom: 20px;
}

progress {
  width: 100%;
  height: 30px;
  border-radius: 15px;
  overflow: hidden;
}

progress::-webkit-progress-bar {
  background-color: #f0f0f0;
}

progress::-webkit-progress-value {
  background: linear-gradient(45deg, #ff6b6b, #feca57);
}

.spinner {
  width: 36px;
  height: 36px;
  vertical-align: middle;
  border: 4px solid #0000001a;
  border-left-color: #09f;
  border-radius: 50%;
  margin-right: 10px;
  animation: 1s infinite spin;
  display: inline-block;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

#loadingText {
  color: #333;
  margin-top: 20px;
  font-weight: bold;
  display: none;
}

.bottom-div {
  margin-top: 60px;
  margin-bottom: 20px;
}

.buymeacoffee {
  margin-top: 60px;
}

/*# sourceMappingURL=index.82c0c5c5.css.map */
